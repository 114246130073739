import type { LinksData } from '@jouzen/ecom-components';
import type {
  Formats,
  RichTranslationValues,
  TranslationValues,
} from 'next-intl';
import type { ReactElement } from 'react';

export interface NextT {
  <TargetKey>(
    key: TargetKey,
    values?: TranslationValues,
    formats?: Partial<Formats>,
  ): string;
  rich<TargetKey>(
    key: TargetKey,
    values?: RichTranslationValues,
    formats?: Partial<Formats>,
  ): string | ReactElement;
}

const localizeLinks = (
  statsigLinks: LinksData[] | undefined,
  t: NextT,
  tValues = {},
) => {
  if (statsigLinks) {
    for (const links of statsigLinks) {
      if (links.links) {
        for (const link of links.links) {
          link.label = t.rich(link.label, tValues);
        }
      }
      if (links.link) {
        links.link.label = t.rich(links.link.label);
        if (links.link.overlay) {
          links.link.overlay.title = t.rich(links.link.overlay.title);
        }
      }
      links.title = t(links.title);
    }
  }
};

export default localizeLinks;
