import {
  type FooterV3Props,
  LinksCategoryId,
  type LinksData,
} from '@jouzen/ecom-components';
import { cloneDeep } from 'lodash';
import { useTranslations } from 'next-intl';
import { type DynamicConfig, useConfig } from 'statsig-react';

import useOuraGen4Enabled from '@/app/hooks/useOuraGen4Enabled';

import isPathMatchToPOP from '../utils/isPathMatchToPOP';
import type { NextT } from '../utils/localizeLinks';
import localizeLinks from '../utils/localizeLinks';
import useLinkModifierConfig from './useLinkModifierConfig';

interface FooterLinkProps extends Omit<DynamicConfig, 'value'> {
  value: {
    footerLinks: FooterV3Props['footerLinks'];
  };
}

const useFooterConfig = (pathname: string): FooterV3Props['footerLinks'] => {
  const t = useTranslations();
  const { config } = useConfig('global_footer_links');
  const footerConfigValue = config.value as FooterLinkProps['value'];
  const activeFooterConfig = Object.keys(footerConfigValue).length > 0;

  const gen4Enabled = useOuraGen4Enabled();
  // determine if current path is gen3 or OR4 POP, or neither
  const { isGen3POP, isPOP } = isPathMatchToPOP(pathname);

  // footer image link modifier config for POP
  const { isConfigActive, statsigLink } = useLinkModifierConfig(
    'pop_modified_footer_image_link',
    gen4Enabled,
    isGen3POP,
  );
  const selectedFooterImageLink = cloneDeep(statsigLink);
  if (selectedFooterImageLink?.label) {
    selectedFooterImageLink.label = t.rich(
      selectedFooterImageLink.label,
    ) as JSX.Element;
  }

  const statsigLinks = cloneDeep(footerConfigValue.footerLinks);
  // translate statsig link fields
  localizeLinks(statsigLinks, t as NextT);

  if (isConfigActive && isPOP && statsigLinks) {
    const index = statsigLinks.findIndex(
      (link) => link.id === LinksCategoryId.FooterCard,
    );
    if (typeof index !== 'undefined' && index !== -1) {
      const _statsigLink = statsigLink as LinksData;
      statsigLinks.splice(index, 1, _statsigLink);
    }
  }

  if (!activeFooterConfig) {
    return undefined;
  }

  return statsigLinks;
};

export default useFooterConfig;
