import plugin from 'tailwindcss/plugin';

import { locales } from '../src/config';

const noJsPlugin = plugin(({ addVariant }) => {
  locales.forEach((locale) => {
    addVariant(locale, `html:lang(${locale}) &`);
  });
});

export default noJsPlugin;
