import { usePathname } from 'next/navigation';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';

import { EventType } from '@/analytics/types';

export interface UsePageAnalytics {
  onFooterLinkClick: (e: MouseEvent<HTMLAnchorElement>) => Promise<void>;
  onFooterSocialLinkClick: (e: { name: string; url: string }) => Promise<void>;
  onHamburgerClick: ({ open }: { open: boolean }) => Promise<void>;
  onHeaderLinkClick: (e: MouseEvent<HTMLAnchorElement>) => Promise<void>;
  onMenuLinkClick: (e: MouseEvent<HTMLAnchorElement>) => Promise<void>;
  onMenuShopButtonClick: (e: MouseEvent<HTMLAnchorElement>) => Promise<void>;
  onShopButtonClick: (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
  sendEmailSubmitAnalytics: (email: string) => Promise<void>;
}

const usePageAnalytics = (): UsePageAnalytics => {
  const path = usePathname();

  // click links in footer
  const handleFooterLinkClick = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>): Promise<void> => {
      const { href } = event.currentTarget;
      const url = new URL(href);
      await window.ouraAnalytics.track(EventType.CTAClicked, {
        location: 'footer',
        path: url.pathname,
      });
    },
    [],
  );

  // click social links in footer
  const handleFooterSocialLinkClick = useCallback(
    async ({ name, url }: { name: string; url: string }): Promise<void> => {
      await window.ouraAnalytics.track(EventType.LinkClicked, {
        cta: name,
        location: 'footer',
        path: url,
      });
    },
    [],
  );

  // open and close the menu
  const handleHamburgerClick = useCallback(
    async ({ open }: { open: boolean }): Promise<void> => {
      await window.ouraAnalytics.track(EventType.HamburgerClicked, {
        location: 'header',
        action: open ? 'open' : 'close',
      });
    },
    [],
  );

  // click links in header
  const handleHeaderLinkClick = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>): Promise<void> => {
      const { href } = event.currentTarget;
      await window.ouraAnalytics.track(EventType.LinkClicked, {
        cta: href,
        location: 'header',
      });
    },
    [],
  );

  // click links in menu
  const handleMenuLinkClick = useCallback(
    async (event: MouseEvent<HTMLAnchorElement>): Promise<void> => {
      const { href } = event.currentTarget;
      await window.ouraAnalytics.track(EventType.LinkClicked, {
        cta: href,
        location: 'menu',
      });
    },
    [],
  );

  // click shop now button in menu
  const handleMenuShopButtonClick = useCallback(async (): Promise<void> => {
    await window.ouraAnalytics.track(EventType.CTAClicked, {
      cta: 'shop_now',
      action: 'go_to_pdp',
      location: 'menu',
      path,
    });
  }, [path]);

  // click shop now button in header
  const handleShopButtonAnalytics = async (): Promise<void> => {
    await window.ouraAnalytics.track(EventType.CTAClicked, {
      cta: 'shop_now',
      action: 'go_to_pdp',
      location: 'header',
      path,
    });
  };

  // submit email form in footer
  const sendEmailSubmitAnalytics = async (email: string): Promise<void> => {
    await window.ouraAnalytics.identify({ email });

    await Promise.all(
      [
        {
          name: EventType.EmailSignupCompleted,
          properties: { path, email },
        },
        {
          name: EventType.UserEmailCaptured,
          properties: { email },
        },
        { name: EventType.MarketingOptInFooter, properties: {} },
      ].map(({ name, properties }) =>
        window.ouraAnalytics.track(name, properties),
      ),
    );
  };

  return {
    onFooterLinkClick: handleFooterLinkClick,
    onFooterSocialLinkClick: handleFooterSocialLinkClick,
    onHamburgerClick: handleHamburgerClick,
    onHeaderLinkClick: handleHeaderLinkClick,
    onMenuLinkClick: handleMenuLinkClick,
    onMenuShopButtonClick: handleMenuShopButtonClick,
    onShopButtonClick: handleShopButtonAnalytics,
    sendEmailSubmitAnalytics,
  };
};

export default usePageAnalytics;
