import {
  BREAKPOINTS_V3,
  type CoreMessageKey,
  LinksCategoryId,
  type LinksData,
  useMediaQuery,
} from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useRef, useState } from 'react';

import { POP_PATHNAME } from '@/app/consts/pathnames';
import useOuraGen4Enabled from '@/app/hooks/useOuraGen4Enabled';

import isPathMatchToPOP from '../utils/isPathMatchToPOP';

type ShopButtonProps =
  | false
  | {
      label: string;
      href: string;
    };

interface ShopButtonHook {
  shopButton: ShopButtonProps;
  observeNode: (node: HTMLDivElement | null) => void;
}

type ShopButtonOptions = Record<string, LinksData>;

const POP_SHOP_BUTTON_OPTIONS: ShopButtonOptions = {
  'oura-dep': {
    id: LinksCategoryId.ShopCategory,
    link: {
      id: 'oura-ring',
      href: '/product/rings/heritage',
      label: 'choose_your_oura_ring' as CoreMessageKey,
    },
  },
  'oura-ring-4': {
    id: LinksCategoryId.ShopCategory,
    link: {
      id: 'oura-ring',
      href: '/product/rings/oura-ring-4/silver',
      label: 'choose_your_oura_ring' as CoreMessageKey,
    },
  },
  'oura-gen3': {
    id: LinksCategoryId.ShopCategory,
    link: {
      id: 'oura-ring',
      href: '/product/rings/oura-gen3/heritage',
      label: 'choose_your_oura_ring' as CoreMessageKey,
    },
  },
};

const getShopLinkModifier = (gen4Enabled: boolean, isGen3POP: boolean) => {
  if (!gen4Enabled) {
    return POP_SHOP_BUTTON_OPTIONS['oura-dep'];
  }

  return isGen3POP
    ? POP_SHOP_BUTTON_OPTIONS['oura-gen3']
    : POP_SHOP_BUTTON_OPTIONS['oura-ring-4'];
};

const useShopButton = (pathname: string): ShopButtonHook => {
  // determine if current path is gen3 or OR4 POP, or neither
  const { isGen3POP, isPOP } = isPathMatchToPOP(pathname);
  const gen4Enabled = useOuraGen4Enabled();

  // shop button modifier config for POP
  const modifiedLink = getShopLinkModifier(gen4Enabled, isGen3POP);

  const [shopButton, setShopButton] = useState<ShopButtonProps>(false);
  const t = useTranslations();

  const mounted = useRef(false);
  const isXLargeScreen = useMediaQuery(
    `(min-width: ${BREAKPOINTS_V3.xLarge}px)`,
  );

  const observeNode = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) return;
      const resizeObserverRef = new ResizeObserver((entries) => {
        const rect = entries[0]?.contentRect;
        if (isPOP && rect?.width && modifiedLink?.link) {
          let _shopButton: ShopButtonProps = false;
          if (isXLargeScreen) {
            _shopButton = {
              href: modifiedLink.link.href,
              label: t(modifiedLink.link.label),
            };
          }
          setShopButton(_shopButton);
        } else {
          setShopButton({
            href: POP_PATHNAME,
            label: t('shop'),
          });
        }
      });

      resizeObserverRef.observe(node);
    },
    [isPOP, modifiedLink?.link, isXLargeScreen, t],
  );

  useEffect(() => {
    if (mounted.current) return;
    if (isPOP && modifiedLink?.link) {
      let _shopButton: ShopButtonProps = false;
      if (isXLargeScreen) {
        _shopButton = {
          href: modifiedLink.link.href,
          label: t(modifiedLink.link.label),
        };
      }
      setShopButton(_shopButton);
    } else {
      setShopButton({
        href: POP_PATHNAME,
        label: t('shop'),
      });
    }
    mounted.current = true;
  }, [isPOP, isXLargeScreen, pathname, modifiedLink, setShopButton, t]);

  return { shopButton, observeNode };
};

export default useShopButton;
