import { useTranslations } from 'next-intl';

import useOuraGen4Enabled from '@/app/hooks/useOuraGen4Enabled';

import Image from '../Image';

export const MenuImageComponent = (): JSX.Element => {
  const t = useTranslations();
  const gen4Enabled = useOuraGen4Enabled();

  return (
    <Image
      alt={t('shop_rings')}
      className="h-36 rounded-lg object-cover md:h-auto"
      height={233}
      src={gen4Enabled ? 'Mask_BXZVD9.png' : 'blue-sky/header-submenu'}
      width={400}
    />
  );
};
