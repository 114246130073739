'use client';

import type {
  FooterV3Props,
  HeaderV3Props,
  LayoutV3Props,
} from '@jouzen/ecom-components';
import { PageLayoutV3 as Layout } from '@jouzen/ecom-components';

import usePageView from '@/analytics/hooks/usePageView';

import { usePageLayout } from './hooks/usePageLayout';

interface PageLayoutProps
  extends Omit<
    LayoutV3Props,
    | 'mainLabel'
    | 'footerProps'
    | 'headerProps'
    | 'eyebrowProps'
    | 'footnotesProps'
  > {
  readonly pageName: string;
  readonly footerProps?: Partial<FooterV3Props>;
  readonly headerProps?: Partial<HeaderV3Props>;
}

const PageLayoutV3 = ({
  children,
  className = '',
  pageName,
  containerProps: parentContainerProps,
  footerProps: parentFooterProps,
  headerProps: parentHeaderProps,
}: PageLayoutProps): JSX.Element => {
  const {
    containerProps,
    eyebrowProps,
    footnotesProps,
    headerProps,
    footerProps,
    observeNode,
  } = usePageLayout({
    parentContainerProps,
    parentFooterProps,
    parentHeaderProps,
  });

  usePageView(pageName);

  return (
    <div data-cy="page-layout-v3" ref={observeNode}>
      <Layout
        className={className}
        containerProps={containerProps}
        eyebrowProps={eyebrowProps!}
        footnotesProps={footnotesProps!}
        footerProps={footerProps}
        headerProps={headerProps}
        mainLabel="mainLabel"
      >
        {children}
      </Layout>
    </div>
  );
};

export default PageLayoutV3;
