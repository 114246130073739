import { cx } from 'class-variance-authority';
import dynamic from 'next/dynamic';
import React from 'react';

import useTailwindThemeValue from '@/app/hooks/useTailwindThemeValue';

const AffirmLogo = dynamic<SVGType>(() => import('./svgs/logo-affirm.svg'));
const BestBuyLogoWhite = dynamic<SVGType>(
  () => import('./svgs/logo-best-buy-white.svg'),
);
const BestBuyLogo = dynamic<SVGType>(() => import('./svgs/logo-best-buy.svg'));
const OuraLogo = dynamic<SVGType>(() => import('./svgs/logo-oura.svg'));
const AmazonLogoFr = dynamic<SVGType>(
  () => import('./svgs/icon_amazon_logo_fr.svg'),
);
const AmazonLogo = dynamic<SVGType>(
  () => import('./svgs/icon_amazon_logo_en.svg'),
);
const AmazonLogoDe = dynamic<SVGType>(
  () => import('./svgs/icon_amazon_logo_de.svg'),
);
const AmazonLogoEs = dynamic<SVGType>(
  () => import('./svgs/icon_amazon_logo_es.svg'),
);
const AmazonLogoIt = dynamic<SVGType>(
  () => import('./svgs/icon_amazon_logo_it.svg'),
);
const AmazonLogoJp = dynamic<SVGType>(
  () => import('./svgs/icon_amazon_logo_jp.svg'),
);

interface SVGType {
  style: {
    width: string;
    height: string;
    maxWidth: string;
  };
}

export type VectorImageKey =
  | 'affirm'
  | 'amazon_logo'
  | 'amazon_logo_fr'
  | 'amazon_logo_de'
  | 'amazon_logo_es'
  | 'amazon_logo_it'
  | 'amazon_logo_jp'
  | 'best-buy'
  | 'best-buy-white'
  | 'oura';

export interface VectorImageProps {
  /** name of image to load */
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  readonly name: VectorImageKey | string;
  /** optional max width in pixels */
  readonly maxWidth?: number;
  /** optional color string */
  readonly color?: string;
  /** optional width in pixels */
  readonly width?: number;
  /** optional height in pixels */
  readonly height?: number;
  /** optional HTML tagname to use for wrapper (defaults to div) */
  readonly Element?: 'div' | 'span';
  /** optional className */
  readonly className?: string;
}

export const DATA_CY = 'vectorimage_icon';

const VectorImage = ({
  name,
  maxWidth,
  width,
  height,
  color = 'sandstone-500',
  Element = 'div',
  className,
}: VectorImageProps): JSX.Element => {
  const colorHex = useTailwindThemeValue('colors', color);

  const getImage = () => {
    const attributes = {
      style: {
        width: width + 'px',
        height: height + 'px',
        maxWidth: maxWidth + 'px',
      },
      'data-cy': DATA_CY,
    };

    switch (name) {
      case 'affirm':
        return <AffirmLogo {...attributes} />;
      case 'amazon_logo':
        return <AmazonLogo {...attributes} />;
      case 'amazon_logo_fr':
        return <AmazonLogoFr {...attributes} />;
      case 'amazon_logo_de':
        return <AmazonLogoDe {...attributes} />;
      case 'amazon_logo_es':
        return <AmazonLogoEs {...attributes} />;
      case 'amazon_logo_it':
        return <AmazonLogoIt {...attributes} />;
      case 'amazon_logo_jp':
        return <AmazonLogoJp {...attributes} />;
      case 'best-buy':
        return <BestBuyLogo {...attributes} />;
      case 'best-buy-white':
        return <BestBuyLogoWhite {...attributes} />;
      default:
        return <OuraLogo {...attributes} />;
    }
  };

  return (
    <Element
      className={cx('fill-current', className)}
      style={{ color: colorHex! }}
      aria-hidden="true"
      data-cy={DATA_CY}
    >
      {getImage()}
    </Element>
  );
};

export default VectorImage;
