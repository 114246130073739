import { useTranslations } from 'next-intl';

import ArtDirectionImage from '../Image/ArtDirectionImage';

export const FooterImageComponent = (): JSX.Element => {
  const t = useTranslations();

  return (
    <ArtDirectionImage
      alt={t('shop_rings')}
      className="h-56 w-full object-cover md:h-full md:w-auto xxl:min-w-full"
      fill
      sizes="(max-width: 767px) 100vw, 25vw"
      srcSet={[
        {
          src: 'blue-sky/footer-cta-image-mobile',
          media: '(max-width: 767px)',
        },
        {
          src: 'blue-sky/footer-cta-image-desktop',
          media: '(min-width: 768px)',
        },
      ]}
    />
  );
};
