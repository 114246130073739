'use client';

import { useConfig } from 'statsig-react';

import type {
  GenericEyebrowProps,
  VectorImageEyebrowProps,
} from '../EyebrowMessage';
import {
  AffiliateMessage,
  GenericEyebrow,
  VectorImageEyebrow,
} from '../EyebrowMessage';
import type { EyebrowProps } from '../types';

const useEyebrowProps = (pathname: string): EyebrowProps => {
  const { config } = useConfig('eyebrow');
  const eyebrowConfig = config.value;
  const hasVectorImage = eyebrowConfig['vectorImage'] !== undefined;
  const activeEyebrowConfig = Object.keys(config.value).length > 0;
  const className =
    eyebrowConfig['className'] ?? 'bg-sandstone-200 text-sandstone-500';
  // Display the hidden afilliate eyebrow by default
  const DEFAULT = {
    displayMessage: AffiliateMessage,
    className: 'hidden',
    id: 'partner_eyebrow',
  };

  // organize by pathname and statsig config
  const routes = config.value['routes'] as string[] | undefined;

  const activeConfigPath =
    routes && routes.length > 0 ? routes.includes(pathname) : pathname === '/';

  if (activeEyebrowConfig && activeConfigPath) {
    return {
      //Default class name styles for the eyebrow if no classname in config is provided
      className: className as string,
      displayMessage: () =>
        hasVectorImage ? (
          <VectorImageEyebrow
            {...(config.value as unknown as VectorImageEyebrowProps)}
          />
        ) : (
          <GenericEyebrow
            {...(config.value as unknown as GenericEyebrowProps)}
          />
        ),
    };
  }

  // Display the hidden affiliate eyebrow by default
  if (pathname === '/product/rings') {
    return DEFAULT;
  }

  return { className: 'hidden', displayMessage: () => <></> };
};

export default useEyebrowProps;
